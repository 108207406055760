<template>
    <template v-if="isLoading">
        <slot name="loading">
            <NdxNotification
                variant="info"
                :model-value="true"
                :duration="0"
                :message="$t('label.loading_data')"
            />
        </slot>
    </template>
    <template v-else-if="!hasAdditionalContent && isEmptyList">
        <slot name="emptyList">
            <NdxNotification
                variant="info"
                :model-value="true"
                :duration="0"
                :message="$t('message.result_no_data')"
            />
        </slot>
    </template>
    <template v-else>
        <slot name="default"></slot>
    </template>

    <div class="pagination" v-if="showPagination">
        <div>
            <template v-if="listingItemCount !== UNDEFINED_ITEM_COUNT">
                {{ listingItemCount }} {{ $tc('label.items', listingItemCount) }}
            </template>
        </div>

        <div class="pages">
            <span v-if="!onFirstPage" @click="firstPage">
                <NdxIcon icon="last-page" size="s" flip-h/>
            </span>
            <span v-if="!onFirstPage" @click="previousPage">
                <NdxIcon icon="drop-left" size="s"/>
            </span>

            <template
                v-for="page in pageDisplays"
            >
                <template v-if="typeof page === 'number'">
                    <span
                        :key="'page_' + page"
                        :class="{active: page === offset/limit}"
                        @click="() => onPageChange(page)"
                    >
                        {{ page + 1 }}
                    </span>
                </template>
                <template v-else-if="page">
                    &hellip;
                </template>
            </template>

            <span v-if="!onLastPage" @click="nextPage">
                <NdxIcon icon="drop-right" size="s"/>
            </span>
            <span v-if="!onLastPage && isLastPageKnown" @click="lastPage">
                <NdxIcon icon="last-page" size="s"/>
            </span>
        </div>

        <div class="pagesizes">
            <template v-if="pageSizes.length > 1">
                <span
                    v-for="pageSize in pageSizes"
                    :key="'size_' + pageSize"
                    :class="{active: pageSize === limit}"
                    @click="() => onPagesizeChange(pageSize)"
                >
                    {{ pageSize }}
                </span>
            </template>
        </div>
    </div>
</template>

<script>
    import { throbberRules } from "@utilities/throbberRules";
    import NdxNotification from "./NdxNotification.vue";
    import NdxIcon from "./NdxIcon.vue";

    const UNDEFINED_ITEM_COUNT = -1;

    export default {
        name: "NdxListing",
        components: {NdxIcon, NdxNotification},
        emits: ['items-changed', 'result-changed'],
        props: {
            storeAction: {
                type: String,
                required: true,
            },
            filterCriteria: {
                type: Object,
                default: function () {
                    return {};
                }
            },
            orderBy: {
                type: [Object, null],
                default: function () {
                    return null;
                }
            },
            throbber: {
                type: String,
                default: throbberRules.FULLSCREEN_SPINNER
            },
            pageSizes: {
                type: Array,
                default: function () {
                    return [20, 50, 100];
                },
            },
            /**
             * if true, the no-result-message is not shown
             */
            hasAdditionalContent: {
                type: Boolean
            }
        },
        data() {
            return {
                UNDEFINED_ITEM_COUNT,
                offset: 0,
                limit: this.pageSizes[0],
                currentPage: 0,
                lastFetchCount: null,
                listingItemCount: UNDEFINED_ITEM_COUNT,
                isLoading: false,
                isEmptyList: false,
                lastDisplayedPageType: null,
                scrollToTop: false
            };
        },
        computed: {
            pageDisplays() {
                let lastDisplayedPageType = '';
                return this.pages.map(page => {
                    // always display the first two pages
                    if (page < 2) {
                        lastDisplayedPageType = 'number';
                        return page;
                    }
                    // always display the last two pages
                    if (page + 3 > this.pages.length) {
                        lastDisplayedPageType = 'number';
                        return page;
                    }
                    // always display the two pages around the current
                    if (page > this.currentPage - 2 && page - 2 < this.currentPage) {
                        lastDisplayedPageType = 'number';
                        return page;
                    }

                    if (lastDisplayedPageType !== 'number') {
                        return false;
                    }

                    lastDisplayedPageType = 'ellipse';
                    return '...';
                });
            },
            pages() {
                if (this.lastFetchCount === 0) {
                    const pageCount = this.currentPage + 1;
                    return Array.from(Array(pageCount).keys());
                }
                if (!this.isLastPageKnown) {
                    const pageCount = this.currentPage + 2;
                    return Array.from(Array(pageCount).keys());
                }

                if (this.listingItemCount % this.limit === 0) {
                    const pageCount = this.listingItemCount / this.limit;
                    return Array.from(Array(pageCount).keys());
                }

                if (this.listingItemCount % this.limit !== 0) {
                    const pageCount = Math.ceil(this.listingItemCount / this.limit);
                    return Array.from(Array(pageCount).keys());
                }

                throw new Error('unexpected path in determining pages');
            },
            onFirstPage() {
                return this.currentPage === 0;
            },
            onLastPage() {
                return this.currentPage >= this.pages.length - 1;
            },
            isLastPageKnown() {
                return this.listingItemCount !== UNDEFINED_ITEM_COUNT;
            },
            showPagination() {
                if (this.isEmptyList || this.isLoading) {
                    return false;
                }

                if (this.isLastPageKnown && this.onLastPage && this.onFirstPage &&
                    this.limit === Math.min(...this.pageSizes)
                ) {
                    return false;
                }

                return this.pages.length > 1 || this.pageSizes.length > 1;
            }
        },
        watch: {
            filterCriteria: {
                deep: true,
                handler: function () {
                    this.offset = 0;
                    this.fetchItems();
                }
            }
        },
        created() {
            this.fetchItems();
        },
        methods: {
            onPagesizeChange(newPagesize) {
                this.limit = newPagesize;
                this.offset = 0;
                this.currentPage = 0;
                this.fetchItems();
            },
            onPageChange(newPage) {
                this.offset = newPage * this.limit;
                this.currentPage = newPage;
                this.fetchItems();
            },
            firstPage() {
                this.onPageChange(0);
            },
            previousPage() {
                this.onPageChange((this.offset/this.limit) - 1);
            },
            nextPage() {
                this.onPageChange((this.offset/this.limit) + 1);
            },
            lastPage() {
                this.onPageChange(this.pages.length - 1);
            },
            fetchItems() {
                this.isLoading = true;
                this.isEmptyList = false;
                this.$store.dispatch(this.storeAction, {
                    filterCriteria: this.filterCriteria,
                    offset: this.offset,
                    limit: this.limit,
                    orderBy: this.orderBy,
                    throbber: this.throbber
                })
                    .then(result => {
                        this.lastFetchCount = result.list?.length;
                        this.listingItemCount = isNaN(result.count) ? UNDEFINED_ITEM_COUNT : result.count;
                        this.isEmptyList = Array.isArray(result.list) ?
                            result.list.length === 0 && this.offset === 0 :
                            !this.listingItemCount;
                        this.sendItemUpdateEvent(result.list);
                        this.$emit('result-changed', result);

                        // do not scroll to top on initial load
                        if (this.scrollToTop) {
                            this.scrollTop();
                        }
                        this.scrollToTop = true;
                    }).catch(e => {
                        console.error(e);
                    })
                    .finally(() => {
                        this.isLoading = false;
                    });
            },
            sendItemUpdateEvent(items) {
                this.$emit('items-changed', items);
            },
            scrollTop() {
                if (this.$el && this.$el.parentElement && this.$el.parentElement.scrollIntoView) {
                    // @see https://developer.mozilla.org/en-US/docs/Web/API/Element/scrollIntoView?retiredLocale=de
                    this.$el.parentElement.scrollIntoView({
                        behavior: 'instant',
                        block: 'nearest',
                        inline: 'nearest'
                    });
                }
            }
        }
    };
</script>

<style scoped lang="scss">
    @import "../../style/variables_ndx";

    .pagination {
        @extend %font-body2;

        background-color: var(--bs-white);
        width: 100%;
        justify-content: space-between;
        border-top: 1px solid var(--bs-gray-300);
        padding: 16px 8px;
        margin-top: 32px;

        &,
        .pages,
        .pagesizes {
            display: flex;
            gap: 12px;

            span {
                cursor: pointer;

                &.active,
                &:hover {
                    color: var(--bs-primary);
                    font-weight: 600;
                }
            }
        }

        .pages {
            justify-content: center;
        }
        .pagesizes {
            justify-content: end;
        }
    }
</style>
