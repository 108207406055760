<template>
    <div v-if="initiated">
        <div class="page-top">
            <slot name="pageTop"></slot>
        </div>
        <div
            class="page-content d-flex flex-grow-1"
            v-window-resize="calculateContentWidth"
        >
            <div
                class="page-content-left-column d-none hideOnPrintView"
                :class="{
                    'd-md-block': leftWidgets.length > 0 || routeRelatedWidgetsLeft.length > 0,
                    'd-xxl-block': showEmptyLeftSide
                }"
            >
                <BaseWidget
                    v-for="(widget, idx) in routeRelatedWidgetsLeft"
                    :key="widget.id"
                    :widget="widget"
                    :class="{'space-top': idx > 0, 'border-0': true}"
                    position="left"
                />
                <BaseWidget
                    v-for="(widget, idx) in leftWidgets"
                    :key="widget.id"
                    :widget="widget"
                    :class="{'space-top': idx > 0 || routeRelatedWidgetsLeft.length > 0}"
                    position="left"
                />
            </div>
            <div
                class="page-content-center-column flex-grow-1 w-100"
                :class="{spacerTop: addTopContentSpacer}"
            >
                <div
                    v-if="topWidgets.length > 0 || routeRelatedWidgetsTop.length > 0"
                    class="page-content-center-column-top hideOnPrintView"
                >
                    <BaseWidget
                        v-for="(widget, idx) in routeRelatedWidgetsTop"
                        :key="widget.id"
                        :widget="widget"
                        :class="{'space-top': idx > 0}"
                        :parent-width="contentWidth"
                        position="top"
                    />
                    <BaseWidget
                        v-for="(widget, idx) in topWidgets"
                        :key="widget.id"
                        :widget="widget"
                        :class="{'space-top': idx > 0 || routeRelatedWidgetsTop.length > 0}"
                        :parent-width="contentWidth"
                        position="top"
                    />
                </div>
                <div class="page-content-center-column-center">
                    <slot name="center-headline"/>
                    <template v-if="centerWidgets.length > 0">
                        <BaseWidget
                            v-for="(widget, idx) in centerWidgets"
                            :key="widget.id"
                            :widget="widget"
                            :class="{'space-top': idx > 0}"
                            :parent-width="contentWidth"
                            position="center"
                        />
                    </template>
                    <slot
                        v-else
                        name="default"
                        :parent-width="contentWidth"
                    />
                </div>
                <div
                    v-if="bottomWidgets.length > 0"
                    class="page-content-center-column-bottom hideOnPrintView"
                >
                    <BaseWidget
                        v-for="(widget, idx) in bottomWidgets"
                        :key="widget.id"
                        :widget="widget"
                        :class="{'space-top': idx > 0 || centerWidgets.length > 0}"
                        :parent-width="contentWidth"
                        position="bottom"
                    />
                </div>
            </div>
            <div
                class="page-content-right-column d-none hideOnPrintView"
                :class="{
                    'd-xl-block': rightWidgets.length > 0 || routeRelatedWidgetsRight.length > 0,
                    'd-xxl-block': showEmptyRightSide
                }"
            >
                <BaseWidget
                    v-for="(widget, idx) in routeRelatedWidgetsRight"
                    :key="widget.id"
                    :widget="widget"
                    :class="{'space-top': idx > 0, 'border-0': true}"
                    position="right"
                />
                <BaseWidget
                    v-for="(widget, idx) in rightWidgets"
                    :key="widget.id"
                    :widget="widget"
                    :class="{'space-top': idx > 0 || routeRelatedWidgetsRight.length > 0}"
                    position="right"
                />
            </div>
        </div>
    </div>
</template>

<script>
    import BaseWidget from "../widgets/BaseWidget";
    import { mapState } from "vuex";

    export default {
        name: 'NdxPageFrame',
        components: {BaseWidget},
        props: {
            wgcId: {
                type: Number
            }
        },
        data() {
            return {
                wgcWidgets: [],
                categoryWidgets: [],
                productWidgets: [],

                wgcInitiated: false,
                categoryInitiated: false,
                productInitiated: false,

                contentWidth: 0
            };
        },
        computed: {
            ...mapState({
                product: state => state.productDetail.product
            }),
            initiated() {
                return this.wgcInitiated && this.categoryInitiated;
            },
            widgets() {
                let widgets = [];

                if (!this.isProductDetails()) {
                    widgets.push(...this.wgcWidgets);
                }

                widgets.push(...this.categoryWidgets);
                widgets.push(...this.productWidgets);

                return widgets;
            },
            topWidgets() {
                return this.widgets.filter(item => item.area === 'top');
            },
            leftWidgets() {
                return this.widgets.filter(item => item.area === 'left');
            },
            rightWidgets() {
                return this.widgets.filter(item => item.area === 'right');
            },
            bottomWidgets() {
                return this.widgets.filter(item => item.area === 'bottom');
            },
            centerWidgets() {
                return this.widgets.filter(item => item.area === 'center');
            },
            routeRelatedWidgetsTop() {
                return [];
            },
            routeRelatedWidgetsLeft() {
                const backBtnRoutes = [
                    'Product', 'Asset', 'OrderItemDetail', 'WorkflowsTaskDetail',
                    'WorkflowsInstanceDetail', 'WorkflowsInstanceCompletedDetail',
                    'BundleItemDetail'
                ];
                if (backBtnRoutes.includes(this.$route.name)) {
                    return [{
                        id: 'backBtn',
                        type: 'BackBtn',
                        displayName: false,
                        name: '',
                        config: {}
                    }];
                }

                if (['Products', 'ProductsInCategory'].includes(this.$route.name)) {
                    const widgetGroupConfig = this.$store.getters['shop/getWgc'](+this.$route.params.id);
                    return [{
                        id: 'productCat' + +this.$route.params.categoryId,
                        type: 'ProductCategoryTreeWidget',
                        displayName: false,
                        name: widgetGroupConfig.label,
                        config: {
                            categoryId: +this.$route.params.categoryId ||
                                this.$store.getters['shop/productRootFolderId'],
                            rootCategoryId:
                                widgetGroupConfig?.config?.productCategoryRootId ||
                                this.$store.getters['shop/productRootFolderId']
                        }
                    }, {
                        id: 'basketBudgetSummary',
                        type: 'BasketBudgetSummary',
                        displayName: false,
                        name: '',
                        config: {}
                    }];
                }

                if (['Favorites'].includes(this.$route.name)) {
                    let widgetGroupConfig = this.$store.getters['shop/getWgc'](
                        this.$store.getters['shop/getRouteByType']('favorites').params.id
                    );
                    return [{
                        id: 'favorites' + this.$route.params.id,
                        type: 'Favorites',
                        displayName: false,
                        name: widgetGroupConfig.label,
                        config: {}
                    }];
                }
                if (['Watchlist'].includes(this.$route.name)) {
                    let widgetGroupConfig = this.$store.getters['shop/getWgc'](
                        this.$store.getters['shop/getRouteByType']('watchlist').params.id
                    );
                    return [{
                        id: 'watchlist' + this.$route.params.id,
                        type: 'Watchlist',
                        displayName: false,
                        name: widgetGroupConfig.label,
                        config: {}
                    }];
                }

                if (['Orders', 'OrderDetails', 'OrderSuccess'].includes(this.$route.name)) {
                    let widgetGroupConfig;
                    if (this.$route.name !== 'OrderSuccess') {
                        widgetGroupConfig = this.$store.getters['shop/getWgc'](+this.$route.params.id);
                    } else {
                        widgetGroupConfig = this.$store.getters['shop/getWgc'](
                            this.$store.getters['shop/getRouteByType']('orders').params.id
                        );
                    }
                    return [{
                        id: 'orders' + this.$route.params.id,
                        type: 'Orders',
                        displayName: false,
                        name: widgetGroupConfig.label,
                        config: {}
                    }];
                }

                if (['Assets', 'AssetsInCategory'].includes(this.$route.name)) {
                    const widgetGroupConfig = this.$store.getters['shop/getWgc'](+this.$route.params.id);
                    if (!widgetGroupConfig) {
                        return [];
                    }
                    return [{
                        id: 'assets' + this.$route.params.id,
                        type: 'AssetCategoryTreeWidget',
                        displayName: false,
                        name: widgetGroupConfig.label,
                        config: {
                            wgc: widgetGroupConfig,
                            categoryId: +this.$route.params.categoryId ||
                                this.$store.getters['shop/assetLibraryRootFolderId'],
                            rootCategoryId: this.$store.getters['shop/assetLibraryRootFolderId']
                        }
                    }];
                }

                if (['News', 'NewsDetail'].includes(this.$route.name)) {
                    return [{
                        id: 'news',
                        type: 'NewsNavWidget',
                        displayName: false,
                        name: '',
                        config: {}
                    }];
                }

                if (['SearchResults'].includes(this.$route.name)) {
                    return [{
                        id: 'search',
                        type: 'SearchResultWidget',
                        displayName: false,
                        name: '',
                        config: {}
                    }];
                }

                const myAccountWhiteList = [
                    'DataCheck', 'MyAccount', 'MyAccountUser', 'MyAccountClient', 'MyAccountAddresses',
                    'MyAccountUserSettings', 'MyAccountSubstitutes', 'MyAccountAbsence'
                ];
                if (myAccountWhiteList.includes(this.$route.name)) {
                    return [{
                        id: 'my-account-nav-widget',
                        type: 'MyAccountNavWidget',
                        displayName: false,
                        name: '',
                        config: {
                            isDataCheck: ['DataCheck'].includes(this.$route.name)
                        }
                    }];
                }

                const budgetsWhiteList = [
                    'Budgets', 'BudgetCategories', 'BudgetProducts'
                ];
                if (budgetsWhiteList.includes(this.$route.name)) {
                    return [{
                        id: 'budgets-nav-widget',
                        type: 'BudgetsNavigation',
                        displayName: false,
                        name: '',
                        config: {}
                    }];
                }

                if (['WorkflowsTasks', 'WorkflowsTasksTypeFiltered'].includes(this.$route.name)) {
                    return [{
                        id: 'workflow-task-nav-widget',
                        type: 'WorkflowNavWidget',
                        displayName: false,
                        name: '',
                        config: {
                            wgcType: 'workflowsTasks',
                            routeName: 'WorkflowsTasksTypeFiltered'
                        }
                    }];
                }

                if (['WorkflowsInstances', 'WorkflowsInstancesTypeFiltered'].includes(this.$route.name)) {
                    return [{
                        id: 'workflow-task-nav-widget',
                        type: 'WorkflowNavWidget',
                        displayName: false,
                        name: '',
                        config: {
                            wgcType: 'workflowsInstances',
                            routeName: 'WorkflowsInstancesTypeFiltered'
                        }
                    }];
                }

                return [];
            },
            routeRelatedWidgetsRight() {
                if (this.$route.name === 'Basket') {
                    return [{
                        id: 'basketSummary',
                        type: 'BasketSummary',
                        displayName: false,
                        name: '',
                        config: {
                            isRightSideWidget: true
                        }
                    }, {
                        id: 'basketBudgetSummary',
                        type: 'BasketBudgetSummary',
                        displayName: false,
                        name: '',
                        config: {}
                    }];
                } else if (this.$route.name === 'Checkout') {
                    return [{
                        id: 'checkoutSummary',
                        type: 'checkoutSummary',
                        displayName: false,
                        name: '',
                        config: {
                            isRightSideWidget: true
                        }
                    }, {
                        id: 'basketBudgetSummary',
                        type: 'BasketBudgetSummary',
                        displayName: false,
                        name: '',
                        config: {}
                    }];
                } else if (this.$route.name === 'OrderSuccess' || this.$route.name === 'OrderDetails') {
                    return [{
                        id: 'orderSummary',
                        type: 'OrderSummary',
                        displayName: false,
                        name: '',
                        config: {
                            isRightSideWidget: true
                        }
                    }, {
                        id: 'basketBudgetSummary',
                        type: 'BasketBudgetSummary',
                        displayName: false,
                        name: '',
                        config: {}
                    }];
                }

                return [];
            },
            showEmptyRightSide() {
                // do not show empty right side on these pages
                let blackList = [
                    'ShopHomepage', 'ShopHomepage1', 'Marketingplanner', 'Custom', 'SearchResults', 'Favorites'
                ];

                // assetsListing
                if (['Assets', 'AssetsInCategory'].indexOf(this.$route.name) > -1 && !this.isAssetsDetails()) {
                    blackList.push(this.$route.name);
                }

                // productsListing
                if (['Products', 'ProductsInCategory'].indexOf(this.$route.name) > -1 && !this.isProductDetails()) {
                    blackList.push(this.$route.name);
                }

                return this.rightWidgets.length === 0
                    && this.routeRelatedWidgetsRight.length === 0
                    && blackList.indexOf(this.$route.name) < 0;
            },
            showEmptyLeftSide() {
                let whiteList = [];

                if (this.isAssetsDetails() || this.isProductDetails()) {
                    whiteList.push(this.$route.name);
                }

                return this.leftWidgets.length === 0
                    && this.routeRelatedWidgetsLeft.length === 0
                    && whiteList.indexOf(this.$route.name) > -1;
            },
            addTopContentSpacer() {
                const firstTopWidgetIsSlider = this.routeRelatedWidgetsTop.length === 0
                    && this.topWidgets.length > 0
                    && this.topWidgets[0].type === 'Slider';

                return !firstTopWidgetIsSlider;
            }
        },
        watch: {
            '$route': function (newRoute, oldRoute) {
                if (newRoute.name !== oldRoute.name) { // if route name differs this component is newly mounted
                    return;
                }

                const newWgcId = this.getRouteWgcId(newRoute);
                const oldWgcId = this.getRouteWgcId(oldRoute);
                if (newWgcId !== oldWgcId) {
                    this.getWgcWidgets(newWgcId);
                }

                const newCatId = this.getRouteCategoryId(newRoute);
                const oldCatId = this.getRouteCategoryId(oldRoute);
                if (newCatId !== oldCatId) {
                    this.getCategoryWidgets(newCatId);
                }

                if (!this.isProductDetails()) {
                    this.getProductWidgets(null, null);
                }
            },
            product: {
                handler(newProduct, oldProduct) {
                    if (newProduct && (!oldProduct || newProduct.id !== oldProduct.id)) {
                        this.getProductWidgets(newProduct.id, this.getRouteProductId(this.$route));
                    }
                }
            },
            initiated: function (newVal) {
                if (newVal === true) {
                    this.$nextTick(() => {
                        this.calculateContentWidth();
                    });
                }
            }
        },
        mounted() {
            let wgcId = this.wgcId;
            if (!wgcId && this.getRouteWgcId(this.$route) !== null) {
                wgcId = this.getRouteWgcId(this.$route);
            }
            this.getWgcWidgets(wgcId);

            const catId = this.getRouteCategoryId(this.$route);
            this.getCategoryWidgets(catId);

            if (!this.isProductDetails()) {
                this.getProductWidgets(null, null);
            }
        },
        methods: {
            getWgcWidgets(wgcId) {
                this.wgcInitiated = false;

                if (wgcId) {
                    this.$store.dispatch('widgets/getWidgets', {
                        parentOid: {
                            'type': 'WidgetGroupConfig',
                            'id': wgcId
                        }
                    }).then((widgets) => {
                        this.wgcWidgets = widgets;
                        this.$nextTick(() => {
                            this.wgcInitiated = true;
                        });
                    }).catch(() => {
                        this.$router.push({name: 'NotFound', params: {notFound: 'pageNotFound'}});
                    });
                } else if (this.$route.name.toLowerCase() === 'searchresults') {
                    const wgcs = this.$store.getters['shop/getWgcsByType']('search');
                    if (!wgcs || wgcs.length !== 1) {
                        this.$router.push({name: 'NotFound', params: {notFound: 'pageNotFound'}});
                    } else {
                        this.$store.dispatch('widgets/getWidgets', {
                            parentOid: {
                                'type': 'WidgetGroupConfig',
                                'id': wgcs[0].id
                            }
                        }).then((widgets) => {
                            this.wgcWidgets = widgets;
                            this.$nextTick(() => {
                                this.wgcInitiated = true;
                            });
                        }).catch(() => {
                            this.$router.push({name: 'NotFound', params: {notFound: 'pageNotFound'}});
                        });
                    }
                } else {
                    this.$nextTick(() => {
                        this.wgcWidgets = [];
                        this.wgcInitiated = true;
                    });
                }
            },
            getRouteWgcId(route) {
                return route.params && 'id' in route.params && +route.params.id > 0
                    ? +route.params.id
                    : null;
            },
            getRouteCategoryId(route) {
                const hasPid = this.getRouteProductId(route) !== null;

                const categoryId = route.params && 'categoryId' in route.params && +route.params.categoryId > 0
                    ? +route.params.categoryId
                    : null;

                return route.name.toLowerCase().includes('product') && !hasPid ? categoryId : null;
            },
            getRouteProductId(route) {
                return route.params && 'pid' in route.params && +route.params.pid > 0
                    ? +route.params.pid
                    : null;
            },
            getRouteAssetId(route) {
                return route.params && 'aid' in route.params && +route.params.aid > 0
                    ? +route.params.aid
                    : null;
            },
            getCategoryWidgets(categoryId) {
                this.categoryInitiated = false;

                if (categoryId && categoryId > 0) {
                    this.$store.dispatch('widgets/getWidgets', {
                        parentOid: {
                            'type': 'SymAclFolderExtension',
                            'id': categoryId
                        }
                    }).then((widgets) => {
                        this.categoryWidgets = widgets;
                        this.$nextTick(() => {
                            this.categoryInitiated = true;
                        });
                    });
                } else {
                    this.categoryWidgets = [];
                    this.$nextTick(() => {
                        this.categoryInitiated = true;
                    });
                }
            },
            getProductWidgets(productId, fallbackProductId) {
                this.productInitiated = false;

                let reqProd = null;
                if (productId && productId > 0) {
                    reqProd = this.$store.dispatch('widgets/getWidgets', {
                        parentOid: {
                            'type': 'ProductCatalogItem',
                            'id': productId
                        }
                    });
                }

                let reqFallbackProd = null;
                if (fallbackProductId && fallbackProductId > 0) {
                    reqFallbackProd = this.$store.dispatch('widgets/getWidgets', {
                        parentOid: {
                            'type': 'ProductCatalogItem',
                            'id': fallbackProductId
                        }
                    });
                }

                Promise.all([reqProd, reqFallbackProd])
                    .then(([widgetsProd, widgetsFallbackProd]) => {
                        if (Array.isArray(widgetsProd) && widgetsProd.length > 0) {
                            this.productWidgets = widgetsProd;
                        } else if (Array.isArray(widgetsFallbackProd) && widgetsFallbackProd.length > 0) {
                            this.productWidgets = widgetsFallbackProd;
                        } else {
                            this.productWidgets = [];
                        }
                        this.$nextTick(() => {
                            this.productInitiated = true;
                        });
                    });
            },
            calculateContentWidth() {
                const centerColumn = this.$el.querySelector('.page-content-center-column');
                if (centerColumn) {
                    let children = [];
                    for (let idx in centerColumn.children) {
                        if (centerColumn.children[idx] &&
                            centerColumn.children[idx].classList &&
                            centerColumn.children[idx].classList !== undefined
                        ) {
                            centerColumn.children[idx].classList.add('d-none');
                            children.push(centerColumn.children[idx]);
                        }
                    }

                    // ATTENTION: There are edge cases where widgets trigger a rerendering inside the browser which
                    // causes a change of the width
                    this.contentWidth = centerColumn.offsetWidth;

                    for (let idx in children) {
                        children[idx].classList.remove('d-none');
                    }
                }
            },

            isProductDetails() {
                return ['Products', 'ProductsInCategory', 'Product'].indexOf(this.$route.name) > -1
                    && this.getRouteProductId(this.$route) !== null;
            },
            isAssetsDetails() {
                return ['Assets', 'AssetsInCategory', 'Asset'].indexOf(this.$route.name) > -1
                    && this.getRouteAssetId(this.$route) !== null;
            }
        }
    };
</script>

<style scoped lang="scss">
    @import "~bootstrap/scss/bootstrap-utilities";

    $spaceTop: 30px;

    .page-top {
        padding-bottom: 32px;

        &:empty {
            display: none;
        }
    }

    .page-content-left-column,
    .page-content-right-column {
        width: 258px;
        min-width: 258px;
        max-width: 258px;
    }

    .page-content-left-column {
        margin-right: 91px;
        margin-top: $spaceTop;
    }

    .page-content-right-column {
        margin-left: 91px;
        margin-top: $spaceTop;
    }

    .page-content-center-column {
        &.spacerTop {
            margin-top: $spaceTop;
        }

        .page-content-center-column-top {
            margin-bottom: 64px;
        }
    }
</style>
